<template>
  <section
    id="application-form"
    class="application-form page-section"
    :class="content.classes"
  >
    <div class="form-container">
      <TitleBlock
        v-if="content.title || content.text"
        :title="content.title"
        :text="content.text"
      />
      <form
        id="form-start"
        @submit.prevent="submitForm"
      >
        <div class="form-content">
          <fieldset>
            <label for="first-name" />
            <input
              id="first-name"
              v-model="formData.firstName"
              type="text"
              placeholder="First name *"
            >
            <p
              v-if="errors.firstName"
              class="error-msg"
            >
              Please enter your first name.
            </p>
          </fieldset>
          <fieldset>
            <label for="last-name" />
            <input
              id="last-name"
              v-model="formData.lastName"
              type="text"
              placeholder="Last name *"
            >
            <p
              v-if="errors.lastName"
              class="error-msg"
            >
              Please enter your last name.
            </p>
          </fieldset>
          <fieldset>
            <label for="email" />
            <input
              id="email"
              v-model="formData.email"
              type="email"
              placeholder="Email *"
            >
            <p
              v-if="errors.email"
              class="error-msg"
            >
              Please provide a valid email.
            </p>
          </fieldset>
          <fieldset>
            <label for="phone-number" />
            <the-mask
              id="phone-number"
              v-model="formData.phone"
              name="phone-number"
              autocomplete="off"
              mask="(###) ###-####"
              :masked="false"
              pattern="^\(?([2-9][0-8][0-9])\)?[\s]?([2-9][0-9]{2})[\-]?([0-9]{4})$"
              title="(123) 456-7890"
              type="tel"
              placeholder="Phone number *"
            />
            <p
              v-if="errors.phone"
              class="error-msg"
            >
              Please enter a valid phone number.
            </p>
          </fieldset>
          <fieldset>
            <label for="zipcode" />
            <the-mask
              id="zipcode"
              v-model="formData.zipcode"
              autocomplete="off"
              type="text"
              mask="#####"
              :masked="false"
              size="5"
              minlength="5"
              pattern="^[0-9]{5}(?:-[0-9]{4})?$"
              placeholder="Zip code *"
            />
            <p
              v-if="errors.zipcode"
              class="error-msg"
            >
              Please enter a valid Zip code.
            </p>
          </fieldset>
          <fieldset class="file-container-wrap">
            <div class="file-container">
              <template v-if="file">
                <span class="file-name">{{ fileName }}</span>
                <img
                  class="remove-file-icon"
                  :src="content.remove_file_icon_image"
                  alt="remove-file-icon-img"
                  @click="handleRemoveFile"
                >
              </template>
              <template v-else>
                <span class="file-name">Resume or CV (optional)</span>
                <button
                  ref="uploadButton"
                  value="upload"
                  type="button"
                  class="btn btn-gray-light"
                  @click="handleUploadButtonClick"
                >
                  UPLOAD
                </button>
              </template>
            </div>
            <label for="file-upload" />
            <input
              id="file-upload"
              ref="fileInput"
              type="file"
              style="display: none"
              accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
              @change="handleFileUpload"
            >
          </fieldset>
        </div>

        <div class="form-hint">
          * Required field
        </div>

        <div
          v-if="isQuizIncompleteError"
          class="error-msg is-general"
        >
          Please answer the questions above the form
        </div>

        <div class="form-submit">
          <button
            class="btn btn-secondary"
            :class="{ 'disabled': isDisabled }"
            type="submit"
            :disabled="isDisabled || null"
            v-html="content.cta_text || 'Submit'"
          />
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
import TitleBlock from './shared/TitleBlock.vue'

export default {
  components: {
    TitleBlock,
  },
  data() {
    return {
      content: this.$quizData.applicationForm || {},
      formData: {
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
        zipcode: null,
      },
      errors: {
        email: false,
        firstName: false,
        lastName: false,
        phone: false,
        zipcode: false,
      },
      file: null,
      fileName: 'file_name.pdf',
      resultData: {},
      isQuizIncompleteError: false,
      isSubmitting: false,
    }
  },
  validations: {
    formData: {
      email: { email, required },
      firstName: { required },
      lastName: { required },
      phone: {
        required,
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
      zipcode: {
        maxLength: maxLength(5),
        minLength: minLength(5),
        required,
      },
    },
  },
  computed: {
    quizResults() {
      return this.$store.getters.RESULTS
    },
    isDisabled() {
      return this.isSubmitting || this.$v.$error || this.$v.$anyError
    },
  },
  methods: {
    handleUploadButtonClick() {
      this.$refs.fileInput.click()
    },
    handleFileUpload() {
      this.file = this.$refs.fileInput.files[0]
      this.fileName = `${this.$refs.fileInput.value.split('\\').slice(-1)[0]}`
    },
    handleRemoveFile() {
      this.$refs.fileInput.value = null
      this.file = null
      this.fileName = 'file_name.pdf'
    },
    async submitForm(e) {
      e.preventDefault()
      this.validateQuizAnswers()
      if (this.validateForm() && !this.isQuizIncompleteError) {
        this.isSubmitting = true
        this.getResultData()
        handleSubmit(this.resultData)
      }
    },
    validateForm() {
      this.$v.$touch()
      const { formData } = this
      const keys = Object.keys(formData)
      this.clearErrors(keys)
      if (this.$v.$anyError) {
        // eslint-disable-next-line no-restricted-syntax
        keys.forEach(key => {
          if (this.$v.formData[key] && this.$v.formData[key].$anyError) {
            this.errors[key] = true
          }
        })
        return false
      }
      return true
    },
    clearErrors(keys) {
      keys.forEach(key => {
        this.errors[key] = false
      })
    },
    getResultData() {
      // Get answer for each question
      const questionData = this.quizResults.map(result => `${result.label} [${Array.isArray(result.content)
        ? result.content.map(content => content.selection).join(',')
        : result.content}]`)

      this.resultData = {
        ...this.formData,
        notes: JSON.stringify(questionData.join(',')),
        resume: this.file,
        srs_id: this.content.srs_id,
        product_id: this.content.product_id,
        emailSubject: this.content.email_subject,
      }
    },
    validateQuizAnswers() {
      const results = this.quizResults.map(i => (i.content ? i.content.toString() : ''))
      this.isQuizIncompleteError = !!results.includes('')
    },
  },
}
</script>

<style scoped>
.btn.disabled {
  background: #c4c4c4;
  cursor: not-allowed;
  pointer-events: none;
}
</style>
