<template>
  <section class="one-page-custom-hero">
    <div class="quiz-hero-content">
      <div class="quiz-hero-content-text">
        <h3
          v-if="content.title"
          v-html="content.title"
        />

        <figure
          v-if="content.sm_hero_image"
          class="media-block is-after-title"
        >
          <picture>
            <img
              alt="Dreamstyle Bathroom"
              :src="content.sm_hero_image"
            >
          </picture>
        </figure>

        <p
          v-if="content.text"
          class="lead"
          v-html="content.text"
        />

        <div
          v-if="content.cta_text"
          class="cta-block"
        >
          <a
            v-scroll-to="'#quiz-start'"
            href="#quiz-start"
            class="btn btn-primary"
            v-html="content.cta_text"
          />
        </div>

        <div
          class="cta-block"
        >
          <a
            v-scroll-to="'#accordion'"
            href="#accordion"
            class="btn btn-primary-underlined"
          >See Job Descriptions</a>
        </div>
      </div>

      <figure
        v-if="content.lg_hero_image"
        class="media-block is-separate"
      >
        <picture>
          <source
            v-if="content.lg_hero_image"
            :srcset="content.lg_hero_image"
            media="(min-width: 1024px)"
          >
          <source
            v-if="content.md_hero_image"
            :srcset="content.md_hero_image"
            media="(min-width: 768px)"
          >
          <img
            alt="Dreamstyle Bathroom"
            :src="content.sm_hero_image"
          >
        </picture>
      </figure>
    </div>
  </section>
</template>

<script>
export default {
  computed: {
    content() {
      return this.$store.getters.QUIZDATA.header
    },
  },
}
</script>
