<template>
  <div>
    <ThesisForm>
      <template #headerSlot>
        <CustomLogo />

        <CustomIntro />
      </template>
      <template #onePageBottom>
        <ApplicationForm />
        <Callout
          v-if="quizData.salesConsultants"
          :content="quizData.salesConsultants"
        />
        <AccordionSection v-if="quizData.accordionSection" />
        <QuoteCarousel v-if="quizData.quoteCarousel" />
        <Callout
          v-if="quizData.finalCallout"
          :content="quizData.finalCallout"
        />
        <Callout
          v-if="quizData.aboutDreamstyle"
          :content="quizData.aboutDreamstyle"
        />
      </template>
    </ThesisForm>
  </div>
</template>

<script>
import CustomLogo from './components/CustomLogo.vue'
import CustomIntro from './components/CustomIntro.vue'
import ApplicationForm from './components/ApplicationForm.vue'
const AccordionSection = () => import('./components/AccordionSection.vue')
const QuoteCarousel = () => import('./components/QuoteCarousel.vue')
const Callout = () => import('./components/Callout.vue')

export default {
  components: {
    CustomLogo,
    CustomIntro,
    ApplicationForm,
    AccordionSection,
    QuoteCarousel,
    Callout,
  },
  data() {
    return {
      quizData: this.$quizData
    }
  },
  created() {
    this.$store.dispatch('INITIALIZE_STORE', this.$quizData)
    this.$store.dispatch('/results/INITIALIZE_RESULTS', this.$quizData.sequences)
  }
}
</script>